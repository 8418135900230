<script lang="ts" setup>
defineProps<{
  background?: string;
}>();

const items = [
  {
    name: "Apache Kafka",
    link: "https://kafka.apache.org",
    image: "/images/ApacheKafka_2-1.svg",
  },
  {
    name: "Apache Spark",
    link: "https://spark.apache.org",
    image: "/images/ApacheSpark_2-1.svg",
  },
  {
    name: "Docker",
    link: "https://www.docker.com",
    image: "/images/Docker_2-1.svg",
  },
  {
    name: "Grafana",
    link: "https://grafana.com",
    image: "/images/Grafana_2-1.svg",
  },
  {
    name: "LLaMAc++",
    link: "https://github.com/ggerganov/llama.cpp",
    image: "/images/LLaMACpp_2-1.svg",
  },
  {
    name: "LangChain",
    link: "https://www.langchain.com",
    image: "/images/LangChain_2-1.svg",
  },
  {
    name: "MongoDB",
    link: "https://www.mongodb.com",
    image: "/images/MongoDB_2-1.svg",
  },
  {
    name: "NLTK",
    link: "https://www.nltk.org/",
    image: "/images/NLTK_2-1.svg",
  },
  {
    name: "Nvidia",
    link: "https://www.nvidia.com/en-us/",
    image: "/images/Nvidia_2-1.svg",
  },
  {
    name: "OpenCV",
    link: "https://opencv.org/",
    image: "/images/OpenCV_2-1.svg",
  },
  {
    name: "Python",
    link: "https://www.python.org/",
    image: "/images/Python_2-1.svg",
  },
  {
    name: "PyTorch Geometric",
    link: "https://pytorch-geometric.readthedocs.io/en/latest/",
    image: "/images/PyTorchGeometric_2-1.svg",
  },
  {
    name: "PyTorch",
    link: "https://pytorch.org/",
    image: "/images/PyTorch_2-1.svg",
  },
  {
    name: "Ultralytics",
    link: "https://www.ultralytics.com/",
    image: "/images/Ultralytics_2-1.svg",
  },
  {
    name: "VTK",
    link: "https://vtk.org/",
    image: "/images/VTK_2-1.svg",
  },
  {
    name: "HuggingFace",
    link: "https://huggingface.co/docs/transformers/index",
    image: "/images/HuggingFace_2-1.svg",
  },
  {
    name: "Text Generation Inference",
    link: "https://github.com/huggingface/text-generation-inference",
    image: "/images/TGI_2-1.svg",
  },
];
</script>

<template>
  <Icons :items="items" :background="background" />
</template>
